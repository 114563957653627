<template>
    <div class="main">
        <div class="loginForm">

            <div>Вход на сайт</div>

            <label class="title" for="login">Логин</label>
            <input id="login" type="text" v-model="login" />

            <label class="title" for="pass">Пароль</label>
            <input id="pass" type="password" v-model="password"/>

            <button :disabled="!valid" class="loginButton" @click="toLogin">Войти</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Logout",
    data() {
        return {
        }
    },
    mounted() {
        localStorage.removeItem("token");
        this.$router.push("/");
    },
}
</script>

<style scoped>
</style>